<template>
  <div class="home ind">
    <!-- SLIDESHOW -->
      <div id="slideShow" class="carousel slide carousel-fade h-100 vertical-center" data-ride="carousel" data-pause="false">
        <div class="carousel-inner vertical-center">
          <div class="carousel-item active" data-interval="5000">
            <img src="../assets/mainTheme/slide/moscow1.jpg" class="d-block w-100" alt="...">
            <div class="carousel-caption display-4 d-none d-md-block">
              <img src="../assets/mainTheme/slide/logo1.png" alt="logo1.png">
              <h5>ДиСКА</h5>
              <p>Диспетчеризация и Системы Комлексной Автоматики</p>
            </div>
          </div>
          <div class="carousel-item" data-interval="5000">
            <img src="../assets/mainTheme/slide/moscow2.jpg" class="d-block w-100" alt="...">
            <div class="carousel-caption display-4 d-none d-md-block">
              <img src="../assets/mainTheme/slide/logo1.png" alt="logo1.png">
              <h5>ДиСКА</h5>
              <p>Диспетчеризация и Системы Комлексной Автоматики</p>
            </div>
          </div>
          <div class="carousel-item" data-interval="5000">
            <img src="../assets/mainTheme/slide/moscow3.jpg" class="d-block w-100" alt="...">
            <div class="carousel-caption display-4 d-none d-md-block">
              <img src="../assets/mainTheme/slide/logo1.png" alt="logo1.png">
              <h5>ДиСКА</h5>
              <p>Диспетчеризация и Системы Комлексной Автоматики</p>
            </div>
          </div>
        </div>
      </div>
    <!-- Контейнер 1 -->
    <div class="container-fluid ind">
      <div class="cont1 jumbotron jumbotron-fluid">
        <h3 class="ind">АВТОМАТИЗАЦИЯ ВАШИХ СИСТЕМ ПОД НАШИМ КОНТРОЛЕМ</h3>
        <hr class="my-4">
        <div class="row col-lg-12 ind">
          <div class="row col-sm-12 col-lg-6 rowCard ind">
            <!-- Карточка ЦТП -->
            <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#ctpModal" style="max-width: 21rem;">
              <div class="card-header">ЦТП</div>
              <div class="card-body text-dark ind">
                <img src="../assets/mainTheme/systems/ctp.jpg" class="card-img" alt="...">
                <!-- Модальное окно ЦТП -->
                <div class="modal fade" id="ctpModal" tabindex="-1" role="dialog" aria-labelledby="ctpModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="ctpModalLabel">ЦТП</h5>
                      </div>
                      <div class="modal-body">
                        В разработке
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Карточка Холодоснабжения} -->
            <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#coldModal" style="max-width: 21rem;">
              <div class="card-header">Холодоснабжение</div>
              <div class="card-body text-dark ind">
                <img src="../assets/mainTheme/systems/cold.jpg" class="card-img" alt="...">
                <!-- Модальное окно Холодоснабжения -->
                <div class="modal fade" id="coldModal" tabindex="-1" role="dialog" aria-labelledby="coldModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="coldModalLabel">Холодоснабжение</h5>
                      </div>
                      <div class="modal-body">
                        В разработке
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Карточка Водопровод/Канализация -->
            <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#waterModal" style="max-width: 21rem;">
              <div class="card-header">Водопровод/Канализация</div>
              <div class="card-body text-dark ind">
                <img src="../assets/mainTheme/systems/water.jpg" class="card-img" alt="...">
                <!-- Модальное окно Водопровод/Канализация -->
                <div class="modal fade" id="waterModal" tabindex="-1" role="dialog" aria-labelledby="waterModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="waterModalLabel">Водопровод/Канализация</h5>
                      </div>
                      <div class="modal-body">
                        В разработке
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row col-sm-12 col-lg-6 ind rowCard">
            <!-- Карточка Диспетчерезации -->
            <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#armModal" style="max-width: 22rem;">
              <div class="card-header">Диспетчеризация</div>
              <div class="card-body text-dark ind">
                <img src="../assets/mainTheme/systems/arm.jpg" class="card-img" alt="...">
                <!-- Модальное окно Диспетчерезации -->
                <div class="modal fade" id="armModal" tabindex="-1" role="dialog" aria-labelledby="armModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="armModalLabel">Диспетчерезация</h5>
                      </div>
                      <div class="modal-body">
                        В разработке
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Карточка Освещения -->
            <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#electricityModal" style="max-width: 21rem;">
              <div class="card-header">Освещение</div>
              <div class="card-body text-dark ind">
                <img src="../assets/mainTheme/systems/electricity.png" class="card-img" alt="...">
                <!-- Модальное окно Освещения -->
                <div class="modal fade" id="electricityModal" tabindex="-1" role="dialog" aria-labelledby="electricityModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="electricityModalLabel">Освещение</h5>
                      </div>
                      <div class="modal-body">
                        В разработке
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Карточка Вентиляции -->
            <div class="cardSys card border-dark mb-3" data-toggle="modal" data-target="#ventModal" style="max-width: 20rem;">
              <div class="card-header">Общеобменная вентиляция</div>
              <div class="card-body text-dark ind">
                <img src="../assets/mainTheme/systems/vent.png" class="card-img" alt="...">
                <!-- Модальное окно Вентиляции -->
                <div class="modal fade" id="ventModal" tabindex="-1" role="dialog" aria-labelledby="ventModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="ventModalLabel">Общеобменная вентиляция</h5>
                      </div>
                      <div class="modal-body">
                        В разработке
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Контейнер 2 -->
    <div class="container-fluid ind">
      <div class="row ind imgService display-1" style="padding: 0">
          <div class="card card-services col-9 col-sm-8 col-md-6 col-lg-4">
            <div class="card-header text-center">
              <h2>Услуги</h2>
            </div>
            <div class="card-body text-dark ind text-sm-left">
              <ul class="listExp">
                <li>Проектирование систем автоматики</li>
                <li>Проектирование систем диспетчеризации</li>
                <li>Строительно-монтажные работы</li>
                <li>Пуско-наладочные работы</li>
                <li>Сервисное и техническое обслуживание</li>
                <li>Поставка оборудования</li>
                <li>Проектирование и сборка шкафов автоматики</li>
                <li>Оптимизация систем под особенности объектов</li>
              </ul>
            </div>
          </div>
      </div>
    </div>
    <!-- Контейнер 3 -->
    <div class="container-fluid ind">
      <div class="jumbotron jumbotron-fluid ind" style="padding: 0">
        <div class="row ind" style="padding-left: 0; padding-right: 0">
          <div class="row col-12 col-md-6 ind" style="padding: 0">
            <div class="imgCool col-6 ind" style="padding: 0">
              <i class="fas fa-user-plus"></i>
              <p>Индивидуальный подход</p>
            </div>
            <div class="imgCool col-6 ind" style="padding: 0">
              <i class="fas fa-thumbs-up"></i>
              <p>Гарантия качества</p>
            </div>
          </div>
          <div class="row col-12 col-md-6 ind" style="padding: 0">
            <div class="imgCool col-6 ind" style="padding: 0">
              <i class="fas fa-check-circle"></i>
              <p>100% завершенных проектов</p>
            </div>
            <div class="imgCool col-6 ind" style="padding: 0">
              <i class="fas fa-user-graduate"></i>
              <p>Профессиональные сотрудники</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Контейнер 4 -->
    <div class="container-fluid ind">
      <hr class="my-4">
      <h1 class="about">О нас</h1>
      <hr class="my-4">
      <div class="row col-lg-12 ind" style="padding: 0">
        <div class="textAbout col-lg-5 col-md-6 col-sm-8 col-10 ind text-left">
          <p class="about">Компания основана в 2015 году. Коллектив состоит из высококвалифицированных специалистов в области автоматизации всех видов инженерных систем, получивших фундаментальное образование в лучших технических вузах Москвы и других городов, а также имеющих большой опыт работы в крупных инженерных и эксплуатационных организациях.</p>

          <p class="about">Мы работаем с большинством представленного на рынке оборудования и программного обеспечения для автоматизации и диспетчеризации зданий, как отечественной разработки и производства: Segnetics, ИнСАТ MasterScada; так и ведущих мировых брендов: (Schneider electric, Siemens, Wago, Beckhoff, SCADA Citect, Indusoft (Indusoft Web Studio), Honeywell, Embedded Systems и другие).</p>

          <p class="about">Стоимость наших услуг поддерживается на достаточно низком уровне - это один из важных аспектов при выборе поставщика услуг в наше время.</p>

          <p class="about">Мы работаем на достижение максимального результата от предоставляемых услуг, что приводит к значительной выгоде для наших заказчиков и партнеров.</p>
        </div>
        <div class="container-fluid ind col-12">
          <div class="row ind col-lg-12">
            <div class="row ind display-1 col-6" style="padding: 0">
              <div class="col-lg-6"><img src="../assets/mainTheme/company/embedded.png" alt="embedded.png"></div>
              <div class="col-lg-6"><img src="../assets/mainTheme/company/schneiderLogo.png" alt="schneiderLogo.png"></div>
            </div>
            <div class="row ind display-1 col-6" style="padding: 0">
              <div class="col-lg-6"><img src="../assets/mainTheme/company/siemensLogo.png" alt="siemensLogo.png"></div>
              <div class="col-lg-6"><img src="../assets/mainTheme/company/segneticsLogo.png" alt="segneticsLogo.png"></div>
            </div>
          </div>
          <div class="row ind col-lg-12">
            <div class="row ind display-1 col-6" style="padding: 0">
              <div class="col-lg-6"><img src="../assets/mainTheme/company/honeywellLogo.png" alt="honeywellLogo.png"></div>
              <div class="col-lg-6"><img src="../assets/mainTheme/company/wagoLogo.png" alt="wagoLogo.png"></div>
            </div>
            <div class="row ind display-1 col-6" style="padding: 0">
              <div class="col-lg-6"><img src="../assets/mainTheme/company/beckhoffLogo.png" alt="beckhoffLogo.png"></div>
              <div class="col-lg-6"><img src="../assets/mainTheme/company/insatLogo.png" alt="insatLogo.png"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.cont1 {
  padding: 1rem 0;
}

.textAbout {
  margin: auto;
}

.home {
  .list-group-item {
    border: none;
    text-align: left;
  }
}

.card {
  margin: auto;
}

.modal-title {
  margin: auto;
  font-size: 1.5rem;
}
.modal-body {
  font-size: 1rem;
}

.about {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  text-indent: 20px;
  font-weight: normal;
  margin-left: 20px;
  margin-right: 20px;
}

li {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: normal;
}

#slideShow {
  .vertical-center {
    max-height: 100vh;

    display: flex;
    align-items: center;
  }
  .carousel-caption {
    font-size: 3rem;
    bottom: initial;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 10vw;
    }
    h5 {
      font-size: larger;
      font-weight: bold;
      font-family: 'Russo One', sans-serif;
    }
    p {
      font-size: 2rem;
      font-family: 'Righteous', cursive;
    }
  }
}

.row {
  padding: 20px
}

.rowCard {
  padding: 0;
}

h1.about{
  color: #ff5935;
  font-size: 2em;
  font-weight: bold;
}

.container-fluid {
  margin-top: 40px;
  margin-bottom: 40px;
}

.card-services {
  border: none;
  margin: auto;
  .card-header {
    background-color: #ff451d;
    color: #ffffff;
  }
}

.jumbotron {
  margin: 0;
  padding-top: 10px
}

.home {
  .cardSys {
    width: 300px;
    height: 300px;
    border: none;
    .card-body {
      overflow: hidden;
    }
    img {
      -moz-transition: all 1s ease-out;
      -o-transition: all 1s ease-out;
      -webkit-transition: all 1s ease-out;
      border-radius: 0;
    }
    img:hover{
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
    }
  }
}

.card-header {
  font-weight: bold;
  background-color: #ff6d4d;
  color: #e0dfdf;
  transition: 0.7s;
}

.cardSys:hover {
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  cursor:pointer;
  .card-header {
    background-color: #ff451d;
    color: #ffffff;
  }
}

p {
  font-weight: bold;
  padding-top: 10px;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
}

h2 {
  font-weight: bold;
}

img {
  max-width: 100%;
  height: auto;
}

.imgCool {
  margin-top: 15px;
  i {
    font-size: 50px;
    color: #ff5935;
  }
}

.listExp {
  padding:0;
  list-style: none;
  cursor:pointer;
  li {
    padding: 6px;
    font-size: 1em;
  }
  li:before {
    padding-right:10px;
    font-weight: bold;
    color: #C0C0C0;
    content: "\2714";
    transition-duration: 0.5s;
  }
  li:hover:before {
    color: #ff6d4d;
    content: "\2714";
  }
}

.imgService {
  text-align: justify;
  margin: auto;
  h3 {
    font-weight: bold;
  }
  li {
    font-size: 14px;
  }
}

</style>

