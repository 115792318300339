<template>
  <div class="ind">
    <div id="mySidenav" class="sidenav text-left">
      <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
      <ul class="ind">
        <li>
          <router-link class="nav-link" to="/" onclick="closeNav()">Главная</router-link>
        </li>
        <li>
          <div class="accordion" id="menuAccord">
            <div class="dropdown">
              <div id="headingOne">
                <a class="btn nav-link dropdown-toggle btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Системы
                </a>
                <div id="collapseOne" class="collapse" aria-labelledby="headingTwo" data-parent="#menuAccord">
                  <div class="card-body">
                    <router-link class="dropdown-item" onclick="closeNav()" to="/dispatching">Диспетчеризация</router-link>
                    <router-link class="dropdown-item" onclick="closeNav()" to="/ventilation">Общеобменная вентиляция</router-link>
                    <router-link class="dropdown-item" onclick="closeNav()" to="/heat">ЦТП</router-link>
                    <router-link class="dropdown-item" onclick="closeNav()" to="/cold">Холодоснабжение</router-link>
                    <router-link class="dropdown-item" onclick="closeNav()" to="/electricity">Освещение</router-link>
                    <router-link class="dropdown-item" onclick="closeNav()" to="/water">Водопровод/Канализация</router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown">
              <div id="headingTwo">
                <a class="btn nav-link dropdown-toggle btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                  Опыт
                </a>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#menuAccord">
                  <div class="card-body">
                    <router-link class="dropdown-item" onclick="closeNav()" to="/personal">Опыт сотрудников</router-link>
                    <router-link class="dropdown-item" onclick="closeNav()" to="/company">Опыт компании</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="icon-bar">
        <a target="_blank" href="https://yandex.ru/maps/213/moscow/house/gazgoldernaya_ulitsa_14/Z04YcQNhTUQDQFtvfXtzd3hhZQ==/?ll=37.741414%2C55.726421&utm_source=main_stripe_big&z=17.66"><i class="fas fa-map-marker-alt"></i></a>
        <a href="mailto:info@diska.su"><i class="fas fa-envelope"></i></a>
      </div>
    </div>
    <div id="backgroundMenu" onclick="closeNav()"></div>

    <nav class="navbar fixed-top navbar-light bg-custom shadow-sm">
      <button onclick="openNav()" class="navbar-toggler">
        <i class="fas fa-bars"></i>
      </button>
      <router-link class="navbar-brand text-right ind" to="/"><span id="navbarBrand">ООО "ДиСКА"</span></router-link>
    </nav>

  </div>
  <router-view/>
</template>

<style lang="scss">

#backgroundMenu {
  z-index: 100;
  background-color: black;
  opacity: 50%;
  position: fixed;
}

.icon-bar {
  width: 250px; /* Full-width */
  background-color: #111; /* Dark-grey background */
  overflow: auto; /* Overflow due to float */
  position: absolute;
  top: 90%;
}

.icon-bar a {
  float: left; /* Float links side by side */
  text-align: center; /* Center-align text */
  width: 43%; /* Equal width (5 icons with 20% width each = 100%) */
  margin: 0;
  padding: 0;
  transition: all 0.3s ease; /* Add transition for hover effects */
  color: white; /* White text color */
  font-size: 36px; /* Increased font size */
}

/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 101; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  li {
    list-style: none;
  }
  ul {
    margin-top: 40px;
  }
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 0;
  text-decoration: none;
  font-size: 22px;
  color: #f1f1f1;
  display: block;
  transition: 0.3s;
  margin-left: 10px;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #ff5935;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.navbar-toggler {

  border-radius: 0;
  border: none;
  height: 100%;
  width: 50px;
}

.navbar-toggler:active, .navbar-toggler:focus {
  outline: none;
}

.navbar-toggler::-moz-focus-inner {
  border: 0;
}

.navbar-toggler:hover {
  background-color: #cdcdcd;
  i {
    color: #ff5935;
  }
}

.navbar {
  position: fixed;
  height: 35px;
  background-color: #ffffff;
  padding: 0;
  z-index: 99;
  span {
    font-family: Roboto, sans-serif;
    color: #000000;
    font-weight: bolder;
    font-size: 0.8em;
  }
}

.navbar-brand {
  font-weight: bold;
  position: fixed;
  top: 0;
  z-index: -1;
  width: 100%;
}

#navbarBrand {
  font-family: 'Russo One', sans-serif;
  padding-right: 10px;
}

.ind {
  padding: 0;
  margin: 0;
}

.accordion {
  .card-body {
    background-color: #2f2f2f;
    a {
      font-size: 15px;
    }
    a:hover {
      background-color: #2f2f2f;
    }
  }
}

</style>
