import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/ventilation',
    name: 'Ventilation',
    component: () => import('../views/compVent.vue')
  },

  {
    path: '/cold',
    name: 'Cold',
    component: () => import('../views/compCold.vue')
  },

  {
    path: '/electricity',
    name: 'Electricity',
    component: () => import('../views/compElectricity.vue')
  },

  {
    path: '/dispatching',
    name: 'Dispatching',
    component: () => import('../views/compDisp.vue')
  },

  {
    path: '/water',
    name: 'Water',
    component: () => import('../views/compWater.vue')
  },

  {
    path: '/personal',
    name: 'Personal',
    component: () => import('../views/expPersonal.vue')
  },

  {
    path: '/company',
    name: 'Company',
    component: () => import('../views/expCo.vue')
  },

  {
    path: '/heat',
    name: 'Heat',
    component: () => import('../views/compCTP.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
